
import { defineComponent, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: 'SideMenu',
  components: {
  },
  props: {
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const pageName = route.name

    const selectedMenu = ref('openinq')
    return {
      router,
      pageName,
      selectedMenu,
      async toSearch (page: string) {
        if (pageName === 'PublicSearchPage') {
          selectedMenu.value = page
          const scrollPage = document.getElementsByClassName('title ' + page)
          const MARGIN_TOP = 20
          window.scrollTo({
            left: scrollPage[0].getBoundingClientRect().left,
            top: scrollPage[0].getBoundingClientRect().top + window.scrollY - MARGIN_TOP,
            behavior: 'smooth',
          })
        } else {
          await router.push({ name: 'PublicSearchPage', params: { scrollPage: page } })
        }
      },
    }
  },
})
