
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import PublicSearch from '@/components/organisms/dms/PublicSearch/PublicSearch.vue'
export default defineComponent({
  components: {
    PublicSearch,
  },
  props: {
    scrollPage: {
      type: String,
      default: '',
    },
  },
  setup (props) {
    const router = useRouter()
    console.log(props.scrollPage)
    return {
      props,
      router,
    }
  },
})
