
import { defineComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import About from '@/components/organisms/dms/PublicSearch/About.vue'
import Request from '@/components/organisms/dms/PublicSearch/Request.vue'
import Inquiry from '@/components/organisms/dms/PublicSearch/Inquiry.vue'
import Notification from '@/components/organisms/dms/PublicSearch/Notification.vue'
import Reference from '@/components/organisms/dms/PublicSearch/Reference.vue'
import SideMenu from '@/components/organisms/dms/common/SideMenu.vue'
import IncludeHtmlViewer from '@/components/templates/IncludeHtmlViewer.vue'

export default defineComponent({
  name: 'PublicSearch',
  components: {
    About,
    Request,
    Inquiry,
    Notification,
    Reference,
    SideMenu,
    IncludeHtmlViewer,
  },
  props: {
    scrollPage: {
      type: String,
      default: '',
    },
  },
  setup (props) {
    const router = useRouter()

    onMounted(() => {
      if (props.scrollPage) {
        const scrollPage = document.getElementsByClassName('title ' + props.scrollPage)
        const MARGIN_TOP = 20
        window.scrollTo({
          left: scrollPage[0].getBoundingClientRect().left,
          top: scrollPage[0].getBoundingClientRect().top + window.scrollY - MARGIN_TOP,
          behavior: 'smooth',
        })
      }
    })

    return {
      router,
    }
  },
})
