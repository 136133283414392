
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import ToPageTop from '@/components/organisms/dms/common/ToPageTop.vue'

export default defineComponent({
  name: 'Reference',
  components: {
    ToPageTop,
  },
  props: {
  },
  setup () {
    const router = useRouter()

    return {
      router,
    }
  },
})
