
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import ToPageTop from '@/components/organisms/dms/common/ToPageTop.vue'

export default defineComponent({
  name: 'About',
  components: {
    ToPageTop,
  },
  props: {
  },
  setup () {
    const router = useRouter()

    return {
      router,

      scrollPage (page: string) {
        const scrollPage = document.getElementsByClassName('title ' + page)
        const MARGIN_TOP = 20
        window.scrollTo({
          left: scrollPage[0].getBoundingClientRect().left,
          top: scrollPage[0].getBoundingClientRect().top + window.scrollY - MARGIN_TOP,
          behavior: 'smooth',
        })
      },
    }
  },
})
