
import { defineComponent } from 'vue'
import DssIcon from '@/components/atoms/DssIcon.vue'

export default defineComponent({
  name: 'ToPageTop',
  components: {
    DssIcon,
  },
  props: {
  },
  setup () {
    return {
      toPageTop () {
        window.scroll({ top: 0, behavior: 'smooth' })
      },
    }
  },
})
